@media (min-width: 600px) {
  .termBody {
    padding-right: 40px;
    padding-left: 40px;
  }

  .TermPageH1 {
    padding-left: 0;
    padding-bottom: 10px;
    margin: 0;
    font-size: 30px;
  }
  
  .TermPageH2 {
    font-family: 'S-CoreDream-5Medium';
    font-size: 20px;
    color: #3D4144;
  }

}

@media (max-width: 600px) {
  .termBody {
    padding-right: 20px;
    padding-left: 20px;
  }

  .TermPageH1 {
    padding-left: 0;
    padding-bottom: 25px;
    margin: 0;
    font-size: px;
  }
  
  .TermPageH2 {
    font-family: 'S-CoreDream-5Medium';
    font-size: 18px;
    color: #3D4144;
  }
}





p {
  margin-bottom: 40px;
}

.content {
  white-space: pre-wrap; /* 줄바꿈, 공백, 탭을 유지하면서 자동 줄바꿈도 적용 */
}

.TermDivider {
  padding: 0;
  margin: 0;
  width: 100%

}